










import {Component, Mixins} from 'vue-property-decorator';
import {vxm} from '@/store';
import CreatePostButton from '@/components/CreatePostButton.vue';
import Feed from '@/components/Feed.vue';
import NoContent from '@/components/NoContent.vue';
import Loader from '@/components/Loader.vue';
import FeedMixin from '@/mixins/FeedMixin';
import PostInterface from '@/types/PostInterface';

@Component({
  name: 'Favorites',
  components: {CreatePostButton, Feed, NoContent, Loader},
})
export default class Favorites extends Mixins(FeedMixin) {
  loading = true;
  feed = [] as PostInterface[];

  get title(): string {
    return `Favorites (${this.feed.length})`;
  }
  get noFavsMessage(): string {
    return 'You have no favorites...\nTry to add one!';
  }

  created() {
    this.getFavs();
  }

  getFavs(): void {
    vxm.post
      .getFavs()
      .then((res: {data: {data: PostInterface[]}}) => {
        this.feed = res.data.data.reverse();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
