















import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import {videoTypes} from '@/constants/videoTypes';
import ProfileActivityPreferencesModal from '@/components/modals/ProfileActivityPreferencesModal.vue';

@Component({components: {ProfileActivityPreferencesModal}})
export default class CreatePostButton extends Vue {
  username = vxm.user.data.username;
  videoTypes = videoTypes;
  showProfileActivityPreferencesModal = false;
  postponedAction = '';

  get userAvatar(): string {
    if (vxm.user.data.mediaId) {
      const link = vxm.user.data.mediaId.link;
      return link.medium || link.origin || require('@/assets/icons/avatar-default.svg');
    }
    return require('@/assets/icons/avatar-default.svg');
  }

  openFileDialogueWindow(input: string): void {
    if (vxm.user.postPreferencesUnset) {
      this.postponedAction = input;
      this.showProfileActivityPreferencesModal = true;
      return;
    }
    const el = document.getElementById(input) as HTMLElement;
    if (el) {
      el.click();
    }
  }
  openNewPostModal() {
    if (vxm.user.postPreferencesUnset) {
      this.postponedAction = 'text';
      this.showProfileActivityPreferencesModal = true;
      return;
    }
    vxm.post.setShowNewPostModal(true);
  }
  addImage(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files: FileList = target.files as FileList;
    if (!files.length) {
      target.value = '';
      return;
    }
    vxm.files.addFiles(files).then(() => {
      this.openNewPostModal();
    });
  }
  addVideo(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (!file) {
      target.value = '';
      return;
    }
    vxm.files.addFile(file).then(() => {
      this.openNewPostModal();
    });
  }
  closeProfileActivityPreferencesModal(): void {
    this.showProfileActivityPreferencesModal = false;
    this.postponedAction = '';
  }
  onUserUpdated(): void {
    this.showProfileActivityPreferencesModal = false;
    if (vxm.user.postPreferencesUnset || !this.postponedAction) {
      return;
    }
    if (this.postponedAction === 'text') {
      this.openNewPostModal();
    } else {
      this.openFileDialogueWindow(this.postponedAction);
    }
  }
}
